import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { Prismic, client } from "../../utils/prismic-api-client"
import LoadingAnimation from "../loading-animation"
import { useUser } from "../../context/user-context"
import ArticleCard from "./article-card"
import { Link } from "gatsby"
import ArticleCardLong from "./article-card-long"
import InfiniteScroll from "react-infinite-scroll-component"

function CategoryArticles({ category }) {
  const user = useUser()
  const [topArticles, setTopArticles] = useState([])
  const [articles, setArticles] = useState([])
  const [processing, setProcessing] = useState(true)

  // pagination states
  const [paginationCount, setPaginationCount] = useState(10)
  const [paginationData, setPaginationData] = useState([])
  const [paginationHasMoreData, setPaginationHasMoreData] = useState(true)
  const paginationDefaultCount = 10
  const [paginationLoadInitialData, setPaginationLoadInitialData] =
    useState(true)

  const getCategoryArticles = async () => {
    const resp = await client.get({
      predicates: [
        Prismic.Predicates.at("document.type", "article"),
        Prismic.Predicates.at("my.article.categories.category", category.id),
      ],
      orderings: { field: "my.article.created_at", direction: "asc" },
      pageSize: 50,
    })
    const data = resp.results.reverse()
    setTopArticles(data.slice(0, 4))
    setArticles(data.slice(4))
    setProcessing(false)
  }

  useEffect(() => {
    getCategoryArticles()
  }, [])

  const userHasArticles =
    user !== null ? (user.articles.length ? true : false) : false

  if (processing) return <LoadingAnimation />

  let topBookmarks
  let bottomBookmarks
  if (userHasArticles) {
    topBookmarks = user.articles.slice(0, 4)
    bottomBookmarks = user.articles.slice(4)
  }

  let topArticlesToShow =
    category.slugs[0] === "my-bookmarks" && userHasArticles
      ? topBookmarks
      : topArticles
  let bottomArticlesToShow =
    category.slugs[0] === "my-bookmarks" && userHasArticles
      ? bottomBookmarks
      : articles

  const fetchData = () => {
    setPaginationCount(paginationCount + paginationDefaultCount)
    setPaginationData(bottomArticlesToShow.slice(0, paginationCount))
    if (paginationCount >= bottomArticlesToShow.length) {
      setPaginationHasMoreData(false)
    }
  }

  if (paginationLoadInitialData) {
    fetchData()
    setPaginationLoadInitialData(false)
  }

  return (
    <div className="pt-12 pb-8">
      <div className="lantern-container px-6 md:px-0 mb-0">
        <div className="pb-0 md:pb-4">
          <Link to="/articles" className="flex items-center">
            <i className="material-icons text-grey text-xl">arrow_back</i>
            <span className="font-semibold text-grey text-base block ml-3 pb-0 pt-0.5">
              Resources
            </span>
          </Link>
        </div>
      </div>
      <div className="lantern-container px-6 md:px-0 mb-8">
        <h2 className="text-4xl leading-tight font-extrabold md:text-5xl pb-2 pt-8">
          {category.data.name}
        </h2>
        <p className="text-base leading-normal mb-12">
          {RichText.asText(category.data.description)}
        </p>
        <div className="flex flex-col md:flex-row md:space-x-4">
          {topArticlesToShow.map((article, i) => (
            <div
              key={i}
              className={`w-full md:w-1/4 bg-white relative rounded-xl h-32 md:h-64 shadow-md mb-4 hover:bg-secondary h-card ${
                i !== 0 ? "md:ml-4" : ""
              }`}
            >
              <ArticleCard
                article={article}
                titleCap={70}
                userHasArticles={userHasArticles}
              />
            </div>
          ))}
        </div>
      </div>
      <InfiniteScroll
        auto={true}
        dataLength={paginationCount}
        next={fetchData}
        hasMore={paginationHasMoreData}
        loader={<LoadingAnimation />}
        endMessage={
          <p className="text-grey text-center font-extralight mb-24">
            <b>You’ve reached the end!</b>
          </p>
        }
      >
        <div className="lantern-container px-6 md:px-0 md:pb-4">
          <div className="">
            {paginationData.map((article, i) => (
              <ArticleCardLong
                key={i}
                article={article}
                userHasArticles={userHasArticles}
              />
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  )
}

CategoryArticles.propTypes = {
  category: PropTypes.object,
}

export default CategoryArticles
