import React from "react"
import Layout from "../layout"
import PropTypes from "prop-types"
import SidebarLayout from "../../components/sidebar/layout"
import CategoryArticles from "./category-articles"
import { useUser } from "../../context/user-context"

function CategoryLayout({ category }) {
  const user = useUser()

  return (
    <div>
      {user !== null ? (
        <SidebarLayout fullWidth>
          <CategoryArticles category={category} />
        </SidebarLayout>
      ) : (
        <Layout>
          <div className="bg-grey-lighter">
            <CategoryArticles category={category} />
          </div>
        </Layout>
      )}
    </div>
  )
}

CategoryLayout.propTypes = {
  category: PropTypes.object,
}

export default CategoryLayout
