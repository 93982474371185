import React from "react"
import PropTypes from "prop-types"
import CategoryLayout from "../components/articles/category-layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"

function CategoryTemplate({ pageContext }) {
  const category = pageContext.content

  return (
    <>
      <SEO
        keywords={[
          `categories lantern, ${category?.data?.name} category, ${category?.data?.name}`,
        ]}
        title={`${category?.data?.name} | End of Life Planning | Lantern`}
        description={RichText.asText(category?.data?.description)}
        excludeSiteMetadataTitle
      />
      <CategoryLayout category={category} />
    </>
  )
}

CategoryTemplate.propTypes = {
  pageContext: PropTypes.object,
}

export default withPrismicPreview(CategoryTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
